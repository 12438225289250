<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Activity Item" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-select
                  label="Category *"
                  :options="categories"
                  v-model="selectedCategory"
                  :error="!!errors.category_id"
                  :error-messages="errors.category_id"
                  @input="delete errors.category_id"
                  no-clear
                ></va-select>
                <va-input
                  label="Label *"
                  v-model="label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                  @input="delete errors.label"
                />
                <va-input
                  label="Alias"
                  v-model="alias"
                  :error="!!errors.alias"
                  :error-messages="errors.alias"
                  @input="delete errors.alias"
                />
                <va-card class="mb-3" title="Image">
                  <div v-if="imageExisting">
                    <a style="display: block;" target="_blank" :href="imageExisting"><img style="max-width: 400px;" :src="imageExisting"></a>
                    <div><va-button small color="danger" @click="imageExisting = deleteSingleImage(imageId) ? '' : imageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.image"
                    v-model="image"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(`image`, 'ActivityItemImage', `ActivityItem[image]`, image[0], 'backend\\modules\\activity\\models\\ActivityItem', $attrs.id ? $attrs.id : null, $attrs.id ? null : sign)">Завантажити</va-button>
                  <div v-if="errors.image" style="color: red;">{{ errors.image[0] }}</div>
                </va-card>
                <va-date-picker
                  label="Published at *"
                  :config="{enableTime: true, locale: {firstDayOfWeek: 1}, dateFormat: 'Y-m-d H:i'}"
                  v-model="publicationDate"
                  @input="delete errors.publication_date"
                  :error="!!errors.publication_date"
                  :error-messages="errors.publication_date"
                  weekDays
                />
                <va-button
                  @click="submit()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Main Label *"
                  v-model="mainLabel"
                  :messages="['H1']"
                  :error="!!errors.main_label"
                  :error-messages="errors.main_label"
                  @input="delete errors.main_label"
                />
                <va-input
                  label="Main Label Second *"
                  v-model="mainLabelSecond"
                  :error="!!errors.main_label_second"
                  :error-messages="errors.main_label_second"
                  @input="delete errors.main_label_second"
                />
                <va-card class="mb-3" title="Image">
                  <div v-if="mainImageExisting">
                    <a style="display: block;" target="_blank" :href="mainImageExisting"><img style="max-width: 400px;" :src="mainImageExisting"></a>
                    <div><va-button small color="danger" @click="mainImageExisting = deleteSingleImage(mainImageId) ? '' : mainImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.main_image"
                    v-model="mainImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(`main_image`, 'ActivityItemMain_image', `ActivityItem[main_image]`, mainImage[0], 'backend\\modules\\activity\\models\\ActivityItem', $attrs.id ? $attrs.id : null, $attrs.id ? null : sign)">Завантажити</va-button>
                  <div v-if="errors.main_image" style="color: red;">{{ errors.main_image[0] }}</div>
                </va-card>
                <va-button
                  @click="submit()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <div class="row">
                  <div class="flex xs6">
                    <va-select
                      v-model="selectedBlock"
                      no-clear
                      :options="blocks">
                    </va-select>
                  </div>
                  <div class="flex xs6">
                    <va-button style="margin-bottom: 10px;" small @click="addInput">Додати</va-button>
                  </div>
                </div>
                <draggable v-model="content" handle=".handle">
                  <div
                    class="mb-4"
                    v-for="(contentItem, index) in content"
                    :key="index"
                  >
                    <va-card stripe="info" :title="contentItem.type">
                      <div class="text-right">
                        <i class="fa fa-align-justify handle"></i>
                      </div>
                      <div v-if="contentItem.type === 'text-block'">
                        <div style="margin-bottom: 15px;">
                          <span>Text *</span>
                          <ckeditor
                            v-model="contentItem.text"
                            :editor="editor"
                            :config="editorConfig"
                            @input="delete errors[`TextBlock[${index}][text]`]"
                          ></ckeditor>
                          <div v-if="errors[`TextBlock[${index}][text]`]" style="color: red;">{{ errors[`TextBlock[${index}][text]`][0] }}</div>
                        </div>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'image-text-block'">
                        <va-toggle small label="Align (Left/Right)" v-model="contentItem.align"></va-toggle>
                        <va-card class="mb-3" title="Image">
                          <a v-if="contentItem.image_existing.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.image_existing.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.image_existing.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            v-model="contentItem.image_new"
                            @input="delete errors[`ImageTextBlock[${index}][image]`]"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]image`, 'builder_image_text_block_image', `ImageTextBlock[${index}][image]`, contentItem.image_new[0], 'common\\models\\builder\\ImageTextBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`ImageTextBlock[${index}][image]`]" style="color: red;">{{ errors[`ImageTextBlock[${index}][image]`][0] }}</div>
                        </va-card>
                        <va-input
                          type="textarea"
                          label="Text *"
                          v-model="contentItem.text"
                          @input="delete errors[`ImageTextBlock[${index}][text]`]"
                          :error="!!errors[`ImageTextBlock[${index}][text]`]"
                          :error-messages="errors[`ImageTextBlock[${index}][text]`]"
                        />
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'video-block'">
                        <va-input
                          label="Video title *"
                          v-model="contentItem.video_title"
                          @input="delete errors[`VideoBlock[${index}][video_title]`]"
                          :error="!!errors[`VideoBlock[${index}][video_title]`]"
                          :error-messages="errors[`VideoBlock[${index}][video_title]`]"
                        />
                        <va-input
                          label="Youtube link *"
                          v-model="contentItem.video_src"
                          @input="delete errors[`VideoBlock[${index}][video_src]`]"
                          :messages="['YouTube only']"
                          :error="!!errors[`VideoBlock[${index}][video_src]`]"
                          :error-messages="errors[`VideoBlock[${index}][video_src]`]"
                        />
                        <va-card class="mb-3" title="Preview image">
                          <a v-if="contentItem.image_existing.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.image_existing.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.image_existing.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            v-model="contentItem.image_new"
                            @input="delete errors[`VideoBlock[${index}][image]`]"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]image`, 'builder_video_block_preview_image', `VideoBlock[${index}][image]`, contentItem.image_new[0], 'common\\models\\builder\\VideoBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`VideoBlock[${index}][image]`]" style="color: red;">{{ errors[`VideoBlock[${index}][image]`][0] }}</div>
                        </va-card>
                        <va-input
                          label="Start video title *"
                          v-model="contentItem.start_video_title"
                          @input="delete errors[`VideoBlock[${index}][start_video_title]`]"
                          :error="!!errors[`VideoBlock[${index}][start_video_title]`]"
                          :error-messages="errors[`VideoBlock[${index}][start_video_title]`]"
                        />
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'two-redactor-block'">
                        <div style="margin-bottom: 15px;">
                          <span>Text left *</span>
                          <ckeditor
                            v-model="contentItem.text_left"
                            :editor="editor"
                            :config="editorConfig"
                            @input="delete errors[`TwoRedactorBlock[${index}][text_left]`]"
                          ></ckeditor>
                          <div v-if="errors[`TwoRedactorBlock[${index}][text_left]`]" style="color: red;">{{ errors[`TwoRedactorBlock[${index}][text_left]`][0] }}</div>
                        </div>
                        <div style="margin-bottom: 15px;">
                          <span>Text right *</span>
                          <ckeditor
                            v-model="contentItem.text_right"
                            :editor="editor"
                            :config="editorConfig"
                            @input="delete errors[`TwoRedactorBlock[${index}][text_right]`]"
                          ></ckeditor>
                          <div v-if="errors[`TwoRedactorBlock[${index}][text_right]`]" style="color: red;">{{ errors[`TwoRedactorBlock[${index}][text_right]`][0] }}</div>
                        </div>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'image-block'">
                        <va-card class="mb-3" title="Image">
                          <a v-if="contentItem.image_existing.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.image_existing.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.image_existing.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            v-model="contentItem.image_new"
                            @input="delete errors[`ImageBlock[${index}][image]`]"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]image`, 'builder_image_block_image', `ImageBlock[${index}][image]`, contentItem.image_new[0], 'common\\models\\builder\\ImageBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`ImageBlock[${index}][image]`]" style="color: red;">{{ errors[`ImageBlock[${index}][image]`][0] }}</div>
                        </va-card>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'double-title-block'">
                        <va-input
                          label="Title filled *"
                          v-model="contentItem.title_filled"
                          @input="delete errors[`DoubleTitleBlock[${index}][title_filled]`]"
                          :error="!!errors[`DoubleTitleBlock[${index}][title_filled]`]"
                          :error-messages="errors[`DoubleTitleBlock[${index}][title_filled]`]"
                        />
                        <va-input
                          label="Title stroked"
                          v-model="contentItem.title_stroked"
                          @input="delete errors[`DoubleTitleBlock[${index}][title_stroked]`]"
                          :error="!!errors[`DoubleTitleBlock[${index}][title_stroked]`]"
                          :error-messages="errors[`DoubleTitleBlock[${index}][title_stroked]`]"
                        />
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'simple-text'">
                        <va-input
                          label="Quote *"
                          type="textarea"
                          v-model="contentItem.title"
                          @input="delete errors[`SimpleTextBlock[${index}][title]`]"
                          :error="!!errors[`SimpleTextBlock[${index}][title]`]"
                          :error-messages="errors[`SimpleTextBlock[${index}][title]`]"
                        />
                        <va-input
                          label="Description"
                          type="textarea"
                          v-model="contentItem.description"
                          @input="delete errors[`SimpleTextBlock[${index}][description]`]"
                          :error="!!errors[`SimpleTextBlock[${index}][description]`]"
                          :error-messages="errors[`SimpleTextBlock[${index}][description]`]"
                        />
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'article-gallery-block'">
                        <va-card class="mb-3" title="Images">
                          <div style="margin-bottom: 20px;" class="row">
                            <div class="flex xs4 text-center" v-for="(image, imageIndex) in contentItem.images_existing" :key="imageIndex">
                              <img style="max-width: 400px;" :src="adminUrl + image.url">
                              <div><va-button @click="deleteImage(index, imageIndex)">Видалити</va-button></div>
                            </div>
                          </div>

                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            @input="delete errors[`ArticleGalleryBlock[${index}][images]`]"
                            v-model="contentItem.image_new"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]images`, 'builder_article_gallery', `ArticleGalleryBlock[${index}][images]`, contentItem.image_new[0], 'common\\models\\builder\\ArticleGalleryBlock', contentItem.id, contentItem.target_sign, contentItem.images_existing, index)">Завантажити</va-button>
                          <div v-if="errors[`ArticleGalleryBlock[${index}][images]`]" style="color: red;">{{ errors[`ArticleGalleryBlock[${index}][images]`][0] }}</div>
                        </va-card>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                    </va-card>
                  </div>
                </draggable>
                <va-button
                  @click="submit()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 3">
                <va-input
                  label="Meta Title"
                  v-model="metaTitle"
                />
                <va-input
                  label="Meta Description"
                  type="textarea"
                  v-model="metaDescription"
                />
                <va-input
                  label="Seo Title"
                  v-model="seoTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>Seo Text</span>
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  label="SEO OpenGraph Title"
                  v-model="seoOpenGraphTitle"
                />
                <va-input
                  type="textarea"
                  label="SEO OpenGraph Description"
                  v-model="seoOpenGraphDescription"
                />
                <va-card class="mb-3" title="SEO OpenGraph Image">
                  <div v-if="seoOpenGraphImageExisting">
                    <a style="display: block;" target="_blank" :href="seoOpenGraphImageExisting"><img style="max-width: 400px;" :src="seoOpenGraphImageExisting"></a>
                    <div><va-button small color="danger" @click="seoOpenGraphImageExisting = deleteSingleImage(seoOpenGraphImageId) ? '' : seoOpenGraphImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="seoOpenGraphImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(`image`, null, `SeoEntity[image]`, seoOpenGraphImage[0], 'common\\models\\SeoEntity', seoId, '')">Завантажити</va-button>
                </va-card>
                <va-button
                  @click="submit()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'
import draggable from 'vuedraggable'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
    draggable: draggable,
  },
  data () {
    return {
      categories: [],
      selectedCategory: {},
      label: '',
      alias: '',
      image: [],
      imageId: '',
      imageExisting: '',
      publicationDate: '2020-11-05 11:22',
      published: false,

      mainLabel: '',
      mainLabelSecond: '',
      mainImage: [],
      mainImageId: '',
      mainImageExisting: '',

      content: [],
      blocks: [
        {
          id: 'text-block',
          text: 'Text Editor Block',
        },
        {
          id: 'image-text-block',
          text: 'Image/Text Block',
        },
        {
          id: 'video-block',
          text: 'Video Block',
        },
        {
          id: 'two-redactor-block',
          text: 'Two Editor Block',
        },
        {
          id: 'image-block',
          text: 'Image Block',
        },
        {
          id: 'double-title-block',
          text: 'Double Title Block',
        },
        {
          id: 'simple-text',
          text: 'Blockquote',
        },
        {
          id: 'article-gallery-block',
          text: 'Article Gallery',
        },
      ],
      selectedBlock: {
        id: 'text-block',
        text: 'Text Editor Block',
      },

      sign: '',

      seoId: '',
      metaTitle: '',
      metaDescription: '',
      seoTitle: '',
      seoText: '',
      seoOpenGraphTitle: '',
      seoOpenGraphDescription: '',
      seoOpenGraphImage: [],
      seoOpenGraphImageId: '',
      seoOpenGraphImageExisting: '',

      errors: [],

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_SHKIL_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      tabTitles: ['Short', 'Main', 'Content', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
    this.sign = this.generateSign(32)
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        category_id: this.selectedCategory ? this.selectedCategory.id : null,
        label: this.label,
        alias: this.alias,
        main_label: this.mainLabel,
        main_label_second: this.mainLabelSecond,
        publication_date: this.publicationDate,

        TextBlock: this.getBlocks('text-block'),
        ImageTextBlock: this.getBlocks('image-text-block'),
        VideoBlock: this.getBlocks('video-block'),
        TwoRedactorBlock: this.getBlocks('two-redactor-block'),
        ImageBlock: this.getBlocks('image-block'),
        DoubleTitleBlock: this.getBlocks('double-title-block'),
        SimpleTextBlock: this.getBlocks('simple-text'),
        ArticleGalleryBlock: this.getBlocks('article-gallery-block'),

        sign: this.sign,

        SeoEntity: {
          title: this.metaTitle,
          description: this.metaDescription,
          seo_title: this.seoTitle,
          text: this.seoText,
          og_title: this.seoOpenGraphTitle,
          og_description: this.seoOpenGraphDescription,
        },
      }
    },
    adminUrl () {
      return process.env.VUE_APP_SHKIL_ADMIN
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    generateSign (length) {
      return Math.random().toString(20).substr(2, length)
    },
    getBlocks (name) {
      const blocks = this.content.filter(i => i.type === name)
      if (blocks.length) {
        const newArray = {}
        blocks.forEach(i => {
          const index = this.content.findIndex(j => {
            return i.id ? i.id === j.id : i.target_sign === j.target_sign
          })
          const newObj = Object.assign({}, i)
          if (i.type === 'image-text-block') {
            i.align = i.align ? 1 : 0
          }
          newArray[index] = newObj
        })
        return newArray
      }
      return []
    },
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.label || !!this.errors.alias || !!this.errors.category_id || !!this.errors.image
      } else if (tab === 1) {
        return !!this.errors.main_label || !!this.errors.main_label_second || !!this.errors.main_image
      } else if (tab === 2) {
        const errors = Object.keys(this.errors)
        return errors.some(i => {
          return i.includes('TextBlock') ||
            i.includes('ImageTextBlock') ||
            i.includes('VideoBlock') ||
            i.includes('TwoRedactorBlock') ||
            i.includes('ImageBlock') ||
            i.includes('DoubleTitleBlock') ||
            i.includes('SimpleTextBlock') ||
            i.includes('ArticleGalleryBlock')
        })
      } else if (tab === 3) {
        return false
      }
      return false
    },
    uploadImage (attr, entityAttribute, param, file, model = 'backend\\modules\\article\\models\\Article', id = this.$attrs.id, sign = this.sign, images = null, contentIndex = null) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', entityAttribute === 'builder_article_gallery' ? '1' : '0')
      if (entityAttribute !== null) {
        formData.append('entity_attribute', entityAttribute)
      }

      if (id) {
        formData.append('id', id)
      }
      if (sign) {
        formData.append('sign', sign)
      }
      axios.post(`${process.env.VUE_APP_SHKIL_API_URL}/admin/upload?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
          if (entityAttribute === 'builder_article_gallery') {
            const resp = JSON.parse(response.data)
            images.push({
              id: resp.imgId,
              url: resp.url,
            })
            this.content[contentIndex].image_new = []
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteSingleImage (id) {
      return axios.delete(`${process.env.VUE_APP_SHKIL_API_URL}/admin/upload/${id}?lang=${this.locale}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    deleteImage (index, imageIndex) {
      const imageId = this.content[index].images_existing[imageIndex].id
      axios.delete(`${process.env.VUE_APP_SHKIL_API_URL}/admin/upload/${imageId}?lang=${this.locale}`)
        .then(response => {
          this.showToast('Successfully deleted')
          this.content[index].images_existing.splice(imageIndex, 1)
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    addInput () {
      switch (this.selectedBlock.id) {
        case 'text-block':
          this.content.push({
            type: 'text-block',
            text: '',
            target_sign: this.generateSign(32),
          })
          break
        case 'image-text-block':
          this.content.push({
            type: 'image-text-block',
            align: false,
            text: '',
            image_existing: '',
            image_new: [],
            target_sign: this.generateSign(32),
          })
          break
        case 'video-block':
          this.content.push({
            type: 'video-block',
            video_title: '',
            video_src: '',
            image_existing: '',
            image_new: [],
            start_video_title: '',
            target_sign: this.generateSign(32),
          })
          break
        case 'two-redactor-block':
          this.content.push({
            type: 'two-redactor-block',
            text_left: '',
            text_right: '',
            target_sign: this.generateSign(32),
          })
          break
        case 'image-block':
          this.content.push({
            type: 'image-block',
            image_existing: '',
            image_new: [],
            target_sign: this.generateSign(32),
          })
          break
        case 'double-title-block':
          this.content.push({
            type: 'double-title-block',
            title_filled: '',
            title_stroked: '',
            target_sign: this.generateSign(32),
          })
          break
        case 'simple-text':
          this.content.push({
            type: 'simple-text',
            title: '',
            description: '',
            target_sign: this.generateSign(32),
          })
          break
        case 'article-gallery-block':
          this.content.push({
            type: 'article-gallery-block',
            images_existing: [],
            image_new: [],
            target_sign: this.generateSign(32),
          })
          break
      }
    },
    removeInput (index) {
      this.content.splice(index, 1)
    },
    submit () {
      if (this.$attrs.id) {
        axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/activity-item/${this.$attrs.id}?lang=${this.locale}`, this.putData)
          .then(response => {
            this.showToast('Successful update')
            this.$router.push({ name: 'shkil-activity-items' })
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_SHKIL_API_URL}/admin/activity-item?lang=${this.locale}`, this.putData)
          .then(response => {
            this.showToast('Successful create')
            this.$router.push({ name: 'shkil-activity-items' })
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            this.showToast('Error')
          })
      }
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/activity-item/info?lang=${this.locale}`)
        .then(response => {
          this.categories = response.data.categories
          if (this.$attrs.id) {
            axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/activity-item/${this.$attrs.id}?lang=${this.locale}`)
              .then(response => {
                this.selectedCategory = this.categories.find(i => i.id === response.data.category_id)
                this.label = response.data.label
                this.alias = response.data.alias
                this.imageExisting = response.data.image ? process.env.VUE_APP_SHKIL_ADMIN + response.data.image : ''
                this.imageId = response.data.imageId
                this.mainLabel = response.data.main_label
                this.mainLabelSecond = response.data.main_label_second
                this.mainImageExisting = response.data.main_image ? process.env.VUE_APP_SHKIL_ADMIN + response.data.main_image : ''
                this.mainImageId = response.data.main_image_id

                this.content = response.data.content
                this.content.forEach(i => {
                  if (i.type === 'image-text-block') {
                    i.align = !!i.align
                  }
                })

                this.seoId = response.data.seo.id
                this.metaTitle = response.data.seo.title ? response.data.seo.title : ''
                this.metaDescription = response.data.seo.description ? response.data.seo.description : ''
                this.seoTitle = response.data.seo.seo_title ? response.data.seo.seo_title : ''
                this.seoText = response.data.seo.text ? response.data.seo.text : ''
                this.seoOpenGraphTitle = response.data.seo.og_title ? response.data.seo.og_title : ''
                this.seoOpenGraphDescription = response.data.seo.og_description ? response.data.seo.og_description : ''
                this.seoOpenGraphImageExisting = response.data.seo.og_image ? process.env.VUE_APP_SHKIL_ADMIN + response.data.seo.og_image : ''
                this.seoOpenGraphImageId = response.data.seo.og_image_id
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}

.handle {
  font-size: 24px;
}
</style>
